import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import ScrollMagic from "scrollmagic";
import { gsap } from 'gsap';
import { SumiText, SumiCharacters, SumiIcon, TogenFull } from '../Branding/Branding';
import { media } from '../../utils/mediaQuery';
import useWindowSize from '../../utils/useWindowSize';

const Wrapper = styled.div<{small?:boolean}>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  ${media.medium`
    align-items: flex-end;
  `}
`

const LogoWrapper = styled.div`
  position: relative;
  margin: 0 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HeroLogo = (props:any) => {
  const { fill } = props;
  const controller = useRef(null);
  const scene = useRef(null);
  const logoRef = useRef();
  const charactersRef = useRef();
  const iconRef = useRef();
  const textRef = useRef();
  const [ width, height ] = useWindowSize();
  

  useEffect(()=>{

    if(!controller.current && !scene.current) {
      controller.current = new ScrollMagic.Controller({loglevel: 0});
      scene.current = new ScrollMagic.Scene({triggerElement: logoRef.current, duration: 300})
        .addTo(controller.current)
        .on('progress', (e: any) => {
          const characterProgress = normalize(e.progress, 0.3, 0.1);
          gsap.to(charactersRef.current,{ duration: .2, opacity: 1-characterProgress, ease: "elastic"});
          const iconProgress = normalize(e.progress, 0.8, 0.5);
          gsap.to(iconRef.current,{ duration: .2, opacity: 1-iconProgress, ease: "elastic"});
          const textProgress = normalize(e.progress, 1, 0.8);
          gsap.to(textRef.current,{ duration: .2, opacity: 1-textProgress, ease: "elastic"});
        })
    }

    if(width > 900) {
      scene.current.triggerHook(.7)
      scene.current.duration(500)
    } else {
      scene.current.triggerHook(.25)
      scene.current.duration(300)
    }
   
  }, [width])

  const normalize = (val, max, min) => { 
    let value = (val - min) / (max - min);
    return value < 0 ? 0 : value > 1 ? 1 : value;
  }

  return (
    <Wrapper>
      <LogoWrapper ref={logoRef}>
        <div ref={charactersRef} style={{margin: '0 0 10px'}}>
          <SumiCharacters fill={fill} width={80}/>
        </div>
        <div ref={iconRef} style={{margin: '0 0 24px'}}>
          <SumiIcon fill={fill} width={70}/>
        </div>
        <div ref={textRef}>
          <SumiText fill={fill} width={100}/>
        </div>
      </LogoWrapper>
    </Wrapper>
  )
}

export default HeroLogo;


const HeroLogoTogen = (props:any) => {
  const { fill } = props;
  const controller = useRef(null);
  const scene = useRef(null);
  const logoRef = useRef();
  const [ width, height ] = useWindowSize();

  useEffect(()=>{

    if(!controller.current && !scene.current) {
      controller.current = new ScrollMagic.Controller({loglevel: 0});
      scene.current = new ScrollMagic.Scene({triggerElement: logoRef.current, duration: 300})
        .addTo(controller.current)
        .on('progress', (e: any) => {
          const logoProgress = normalize(e.progress, 1, 0.8);
          gsap.to(logoRef.current,{ duration: .2, opacity: 1-logoProgress, ease: "elastic"});
        })
    }

    if(width > 900) {
      scene.current.triggerHook(.7)
      scene.current.duration(500)
    } else {
      scene.current.triggerHook(.25)
      scene.current.duration(300)
    }
   
  }, [width])

  const normalize = (val, max, min) => { 
    let value = (val - min) / (max - min);
    return value < 0 ? 0 : value > 1 ? 1 : value;
  }
  return (
    <Wrapper>
        <LogoWrapper ref={logoRef}>
          <TogenFull fill={fill}/>
        </LogoWrapper>
    </Wrapper>
  )
}

export { HeroLogoTogen }