import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const HeroStatementWrapper = styled.blockquote` 
  ${fontstack.default}
  ${type('subtitle')}
  font-weight: normal;
  margin: 0;

  ${media.medium`
    ${type('blockquote')}
    margin: 0 0 45px;
  `}
`

const LargeHeaderWrapper = styled.h2`
  ${fontstack.default}
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 20px;

  ${media.medium`
    ${type('heading2')}
    margin: 0 0 45px;
  `}

  
`

const MediumHeaderWrapper = styled.h2`
  ${fontstack.default}
  ${type('body')}
  font-weight: 500;
  margin: 0 0 20px;

  ${media.medium`
    ${type('subtitle')}
    margin: 0 0 45px;
  `}
`

const HeroStatement = (props:any) => {
  const { children } = props;
  return (
    <HeroStatementWrapper>{children}</HeroStatementWrapper>
  )
}

const LargeHeader = forwardRef((props:any, ref:React.ForwardedRef<HTMLHeadingElement>) => {
  const { children, ...rest } = props;
  
  return (
    <LargeHeaderWrapper ref={ref} {...rest}>{children}</LargeHeaderWrapper>
  )
})

const MediumHeader = (props:any) => {
  const { children } = props;
  
  return (
    <MediumHeaderWrapper>{children}</MediumHeaderWrapper>
  )
}

export { 
  HeroStatement,
  LargeHeader,
  MediumHeader
};