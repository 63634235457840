import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { LazyBackgroundBlur } from '@manualengineering/react-lazyblur';
import { HeroLogoTogen } from './HeroLogo';
import HeroImage from './HeroImage';
import useWindowSize from '../../utils/useWindowSize';


const Wrapper = styled.div<{small?:boolean}>`
  position: relative;
  width: 100%;
  height: ${props => props.small ? '80vh' : '100vh' };
`

const Hero = forwardRef((props:any, ref:React.ForwardedRef<HTMLDivElement>) => {
  const { desktop, mobile, small, logo } = props;
  const [ width, height ] = useWindowSize();

  return (
    <Wrapper ref={ref} small={small}>
      { logo ? <HeroLogoTogen fill="white" /> : null }
      { width > 900 ? <HeroImage src={desktop.src} /> :
        <HeroImage src={mobile.src} />
      }
    </Wrapper>
  )
})

export default Hero;