import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div` 
  width: 100%;
  height: 100%;
`

const ImageBg = styled.div<{src:string, loaded:boolean}>`
  background: url('${props => props.src}') no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: ${props => props.loaded ? 1 : 0};
  transition: opacity 800ms ease;
`

const HeroImage = (props) => {
  const { src } = props;
  const [ Loaded, setLoaded ] = useState(false);

  useEffect(() => {
    const image = new Image();

    image.onload = () => {
      setLoaded(true);
    }

    image.src = src;
  }, []);
  return (
    <Wrapper>
      <ImageBg src={src} loaded={Loaded}/>
    </Wrapper>
  )
}

export default HeroImage;