import { css } from 'styled-components';

export const type = (type) => {

  const types = {
    blockquote: css` 
      font-weight: 400;
      font-size: 45px;
      line-height: 55px;
    `,
    heading1: css`
      font-weight: 500;
      font-size: 40px;
      line-height: 48px;
    `,
    heading2: css`
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
    `,
    subtitle: css`
      font-weight: 500;
      font-size: 25px;
      line-height: 34px;
    `,
    body: css`
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
    `,
    caption: css`
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
    `,
    overline: css`
      font-weight: normal;
      font-size: 10px;
      line-height: 14px;
    `
  }

  return types[type];
}