import React from 'react';
import styled from 'styled-components';
import SumiCharactersLogo from './assets/sumi-characters.svg';
import SumiTextLogo from './assets/sumi-text.svg';
import SumiIconLogo from './assets/sumi-icon.svg';
import TogenLogo from './assets/togen.svg';
import TogenTextLogo from './assets/togen-text.svg';
import TogenFooterLogo from './assets/togen-footer.svg';

const SumiText = (props:any) => {
  const { fill, width, height } = props;

  const logoStyle = {
    transition: 'fill 200ms ease',
    fill: fill || 'black',
    width: width || 'auto',
    height: height || 'auto'
  }
  return (
    <SumiTextLogo style={logoStyle}/>
  )
}

const SumiIcon = (props:any) => {  

  const { fill, width, height } = props;

  const iconStyle = {
    fill: fill || 'black',
    width: width || 'auto',
    height: height || 'auto'
  }

  return (
    <SumiIconLogo style={iconStyle}/>
  )
}

const SumiCharacters = (props:any) => {  

  const { fill, width, height } = props;

  const iconStyle = {
    fill: fill || 'black',
    width: width || 'auto',
    height: height || 'auto'
  }

  return (
    <SumiCharactersLogo style={iconStyle}/>
  )
}

const SumiFullWrapper = styled.div<{ width?: number, height?: number }>`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: ${props => props.width ? `${props.width}px` : 'auto'};
  height: ${props => props.height ? `${props.height}px` : 'auto'};
`

const SumiFullLogo = styled.div`
  position: relative;
  width: 70%;
  margin: 0 0 24px;
`

const SumiFull = (props:any) => {
  const { fill, width, height } = props;

  return (
    <SumiFullWrapper width={width} height={height}>  
      <SumiFullLogo>
        <SumiIcon fill={fill}/>
      </SumiFullLogo>
      <SumiTextLogo fill={fill}/>
    </SumiFullWrapper>
  )
}

const TogenText = (props:any) => {
  const { fill, width, height } = props;

  const logoStyle = {
    transition: 'fill 200ms ease',
    fill: fill || 'black',
    width: width || 'auto',
    height: height || 'auto'
  }
  return (
    <TogenTextLogo style={logoStyle}/>
  )
}

const TogenFull = (props:any) => {  

  const { fill, width, height } = props;

  const iconStyle = {
    fill: fill || 'black',
    width: width || 'auto',
    height: height || 'auto'
  }

  return (
    <TogenLogo style={iconStyle}/>
  )
}

const TogenFooter = (props:any) => {  

  const { fill, width, height } = props;

  const iconStyle = {
    fill: fill || 'black',
    width: width || 'auto',
    height: height || 'auto'
  }

  return (
    <TogenFooterLogo style={iconStyle}/>
  )
}

export {
  SumiText,
  SumiCharacters,
  SumiIcon,
  SumiFull,
  TogenText,
  TogenFull,
  TogenFooter
}