import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const PTag = styled.p`
  ${fontstack.default}
  font-size: 14px;
  line-height: 1.53;
  margin: 0 0 20px; 

  ${media.medium`
    ${type('body')}
    margin: 0 0 40px; 
  `}

  &:last-of-type{
      margin-bottom: 0;
  }
`

const AnchorTag = styled.a`
  ${fontstack.default}
  font-size: 14px;
  text-decoration: underline;
  color: #000000;
  opacity: .5;

  ${media.medium`
    ${type('body')}
  `}
`

const Paragraph = props => {
    const { children } = props;
    return (
        <PTag {...props}>{children}</PTag>
    )
}

const Strong = props => {
    const { children } = props;
    return (
        <strong {...props}>{children}</strong>
    )
}

const Em = props => {
    const { children } = props;
    return (
        <em {...props}>{children}</em>
    )
}

const Anchor = props => {
  const { children } = props;
  return (
      <AnchorTag {...props}>{children}</AnchorTag>
  )
}

export { 
    Paragraph,
    Strong,
    Em,
    Anchor
 };